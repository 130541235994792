enum ColorRow {
  GREEN = '#A8E4A0',
  YELLOW = '#ffff005c',
  RED = 'rgba(255,69,0, 1)',
  LIGHT_SALMON = 'rgba(255,160,122, 1)',
  WHITE = '#ffffff'
}

enum ExtentApartment {
  Default = '0',
  InWork = '1',
  Accepted = '2',
  NotAccepted = '3'
}

type ColorExtent = {
  [key in ExtentApartment]: ColorRow
}

const ColorRowByExtent = {
  [ExtentApartment.Default]: ColorRow.WHITE,
  [ExtentApartment.InWork]: ColorRow.YELLOW,
  [ExtentApartment.Accepted]: ColorRow.GREEN,
  [ExtentApartment.NotAccepted]: ColorRow.RED
}

export { ColorRow, ExtentApartment, ColorRowByExtent, type ColorExtent }
