import { LayoutPage } from '#components'

import { CpiAnalytics } from './components'

const HomePage = () => (
  <LayoutPage title="Аналитика">
    <CpiAnalytics />
  </LayoutPage>
)

export default HomePage
