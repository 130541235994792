import { PrintTwoTone, Search } from '@mui/icons-material'
import { Button, Card, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react'

import { useSearchTimeRemarksByHouseMutation } from '#api/house-api'
import { generateUrl } from '#utils'

import { RemarkStatus } from '../../types'
import { CpiTable, HouseSelected, SelectTimeRange } from './components'

const CpiAnalytics = () => {
  const [searchTimeRemarksByHouse] = useSearchTimeRemarksByHouseMutation()
  const [houseIds, setHouseIds] = useState<number[]>([])
  const [time, setTime] = useState<Date[] | null>(null)
  const [cpiRemarks, setCpiRemarks] = useState<RemarkStatus[] | null>(null)
  const isActiveWidget = Boolean(houseIds.length && time?.[0] && time?.[1])

  const onLinkPrintRemark = () => {
    const json = JSON.stringify({
      startDate: time?.[0] as Date,
      endDate: time?.[1] as Date,
      houseIds
    })

    window.open(generateUrl(`pdf/house/remark/status/cpi?json=${json}`), '_blank')
  }

  const loadData = async () => {
    const result = await searchTimeRemarksByHouse({
      houseIds,
      startDate: (time?.[0] ?? 0) as Date,
      endDate: (time?.[1] ?? 0) as Date
    })

    if ('data' in result) {
      setCpiRemarks(result.data as unknown as RemarkStatus[])
    }
  }

  return (
    <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
      <CardContent>
        <Stack justifyContent="space-between" direction="row" spacing={2}>
          <Typography variant="h6">Отчет по выполнению работ по объекту:</Typography>

          <IconButton disabled={!isActiveWidget} onClick={onLinkPrintRemark} size="large" color="primary">
            <PrintTwoTone fontSize="large" color="error" />
          </IconButton>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HouseSelected houseIds={houseIds} setHouseIds={setHouseIds} />
          </Grid>
          <Grid item xs={12}>
            <SelectTimeRange setTime={setTime} />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={loadData} disabled={!isActiveWidget} variant="outlined" endIcon={<Search />}>
              Поиск
            </Button>
          </Grid>
          <Grid item xs={12}>
            {cpiRemarks && <CpiTable cpiRemarks={cpiRemarks} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export { CpiAnalytics }
