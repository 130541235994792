import { Print } from '@mui/icons-material'
import { Avatar, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material'

import { AcceptanceComment } from '#api/types'
import { getFullName } from '#utils'

type Props = {
  comment: AcceptanceComment
  authUserId?: number
  name?: string
  onPrintCard?: () => void
}

const firstLetters = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0))
    .join('')

const Comment = ({ comment, authUserId, name, onPrintCard }: Props) => {
  const isMe = authUserId === comment.user_id
  const fullName = getFullName(comment.user)

  const headerOptions = authUserId
    ? {
        avatar: <Avatar aria-label="recipe">{firstLetters(fullName)}</Avatar>,
        title: fullName,
        subheader: new Date(comment.created_at).toLocaleString()
      }
    : {
        avatar: <Avatar aria-label="recipe">{firstLetters(fullName)}</Avatar>,
        title: name,
        subheader: new Date(comment.created_at).toLocaleString(),
        action: (
          <IconButton onClick={onPrintCard} aria-label="settings">
            <Print />
          </IconButton>
        )
      }

  return (
    <Card
      variant={!authUserId ? 'outlined' : 'elevation'}
      sx={{ maxWidth: authUserId ? '85%' : '99%', ml: isMe ? '14% !important' : '0' }}
    >
      <CardHeader {...headerOptions} />
      <CardContent sx={{ pt: 0, pb: 0 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {comment.comment ?? 'Замечание не принято'}
        </Typography>
      </CardContent>
    </Card>
  )
}

export { Comment }
