import { ApartmentType } from '#api/types'

const NameByType: {
  [key in ApartmentType]: string
} = {
  [ApartmentType.Apartment]: 'Квартира',
  [ApartmentType.Office]: 'Офис',
  [ApartmentType.Storage]: 'Кладовка',
  [ApartmentType.Parking]: 'Машино-место'
}

const CutNameByType: {
  [key in ApartmentType]: string
} = {
  [ApartmentType.Apartment]: 'Кв',
  [ApartmentType.Office]: 'Оф',
  [ApartmentType.Storage]: 'Кл',
  [ApartmentType.Parking]: 'Мм'
}

const getNameByType = (type: ApartmentType, isCut = false) => (isCut ? CutNameByType : NameByType)[type]

export { getNameByType, NameByType, CutNameByType }
