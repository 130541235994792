import { Box, CssBaseline } from '@mui/material'
import { useEffect, useRef } from 'react'

import { useCommentsByAcceptanceIdMutation } from '#api/note-api'
import { RemarksByApartment } from '#api/types'
import { useWindowDimensions } from '#hooks/use-window-dimensions'

import { FormSendComment } from './components'
import { Comments } from './components/comments'

type Props = {
  remark: RemarksByApartment
}

const RemarkComments = ({ remark }: Props) => {
  const { height } = useWindowDimensions()
  const ref = useRef<HTMLDivElement>(null)
  const [getCommentsByAcceptanceId] = useCommentsByAcceptanceIdMutation()

  useEffect(() => {
    getCommentsByAcceptanceId(remark.id).then(scrollToBottom)
  }, [remark.id])

  const scrollToBottom = () => {
    if (ref?.current?.scrollHeight) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }

  useEffect(scrollToBottom, [])

  return (
    <>
      <Box sx={{ pb: 2, pl: 2, pt: 2, height: height - 400, overflowY: 'auto' }} ref={ref}>
        <CssBaseline />

        <Comments remark={remark} />
      </Box>
      <Box sx={{ pl: 2 }}>
        <FormSendComment scrollToBottom={scrollToBottom} remark={remark} />
      </Box>
    </>
  )
}

export { RemarkComments }
