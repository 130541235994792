import { number, object, string, TypeOf } from 'zod'

const CreateCommentByRemark = object({
  acceptance_id: number().optional(),
  comment: string().optional()
})

type CreateCommentByRemarkInput = TypeOf<typeof CreateCommentByRemark>

export { type CreateCommentByRemarkInput, CreateCommentByRemark }
