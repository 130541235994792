import { Print } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import { useState } from 'react'

import { useInfoLogMutation } from '#api/userApi'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

const ProfileLogs = () => {
  const [logs, setLogs] = useState('')
  const [getInfoLog, { isLoading }] = useInfoLogMutation()
  const { isAdminRight } = useRightCurrentUser()

  const onPrintLog = async () => {
    const response = await getInfoLog(null)

    if ('data' in response) {
      setLogs(response.data)
    }
  }

  if (!isAdminRight) {
    return null
  }

  return (
    <>
      <LoadingButton
        loading={isLoading}
        sx={{ width: '240px' }}
        onClick={onPrintLog}
        startIcon={<Print />}
        variant="contained"
      >
        Показать логи
      </LoadingButton>

      {logs && <TextField sx={{ mt: 2, display: 'block' }} value={logs} fullWidth multiline rows={25} maxRows={25} />}
    </>
  )
}

export { ProfileLogs }
