import { Sync } from '@mui/icons-material'
import { Button } from '@mui/material'

type Props = {
  handler: () => Promise<void>
}

const TwooStep = ({ handler }: Props) => (
  <>
    Нажмите кнопку <br /> <br />
    <Button variant="contained" color="success" onClick={handler} endIcon={<Sync />}>
      Синхронизировать
    </Button>
  </>
)

export { TwooStep }
