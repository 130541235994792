import { Button, ButtonGroup } from '@mui/material'
import { Fragment } from 'react'

import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { getFullAddress } from '#utils'

import { SelectedContext } from '../../context'

const HouseSelected = () => {
  const houses = useAppSelector((state) => state.houseState.houses)
  const { houseId, setHouseId } = useContextSafe(SelectedContext)

  return (
    <Fragment>
      Выберите дом:
      <ButtonGroup sx={{ ml: 2 }} variant="outlined">
        {houses.map((house) => (
          <Button
            variant={houseId === house.id ? 'contained' : 'outlined'}
            onClick={() => setHouseId(house.id)}
            key={house.id}
          >
            {getFullAddress(house)}
          </Button>
        ))}
      </ButtonGroup>
    </Fragment>
  )
}

export { HouseSelected }
