import { Table } from '#components'

import { HeadCell } from '../../../../../../components/table/type'
import { RemarkStatus } from '../../../../types'
import { useCell } from './hooks'

type Props = {
  cpiRemarks: RemarkStatus[]
}

const CpiTable = ({ cpiRemarks }: Props) => {
  const cells: HeadCell<RemarkStatus>[] = useCell()

  return (
    <Table
      isSelect={false}
      hasTableToolbar={false}
      propKey="fio"
      defaultOrder="fio"
      perPage={25}
      headCells={cells}
      hasDetailPagination={true}
      tablePage={1}
      dataSource={cpiRemarks}
    />
  )
}

export { CpiTable }
