import { Print } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useApartmentsInfoByHomeMutation, useHouseMutation } from '#api/house-api'
import { House } from '#api/types'
import { LayoutPage, Table } from '#components'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'
import { buildGroupByApartment, generateUrl, getFullAddress } from '#utils'

import FullScreenLoader from '../../components/FullScreenLoader'
import { setPageApartment } from '../../redux/features/apartmentsPageSlice'
import { useBreadcrumbs, useDataTable } from './hooks'

const ApartmentsPage = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const pageByHome = useAppSelector((state) => state.apartmentsPageState.pageByHome)
  const types = useAppSelector((state) => state.houseState.types)
  const apartmentsInfoByHome = useAppSelector((state) => state.houseState.remarksApartmentsByHome)
  const [house, setHouse] = useState<House | null>(null)
  const [getHouse] = useHouseMutation()
  const { isAdminRight, isSpecialistRight } = useRightCurrentUser()
  const isRight = isAdminRight || isSpecialistRight

  const [getApartmentsInfoByHome] = useApartmentsInfoByHomeMutation()
  const { houseId } = params ?? { houseId: 0 }

  useEffect(() => {
    getApartmentsInfoByHome(Number(houseId))
    getHouse(Number(houseId)).then((data) => {
      if ('data' in data) {
        setHouse(data.data)
      }
    })
  }, [])

  const groupByApartment = buildGroupByApartment(apartmentsInfoByHome)

  const { headCells, dataSource } = useDataTable({
    house,
    types,
    groupByApartment
  })

  if (!house) {
    return <FullScreenLoader />
  }

  const fullAddress = getFullAddress(house)
  const pageMemo = pageByHome?.[fullAddress] ?? 1

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const breadcrumbs = useBreadcrumbs({ fullAddress })

  const callbackSavePage = (numberPage: number) => {
    const newPage = {
      ...pageByHome,
      [fullAddress]: numberPage
    }

    dispatch(setPageApartment(newPage))
  }

  const onPrintCard = () => {
    window.open(generateUrl(`pdf/house/info/${house.id}`), '_blank')
  }

  return (
    <LayoutPage breadcrumbs={breadcrumbs}>
      <Table
        isSelect={false}
        hasTableToolbar={true}
        tableName={
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {fullAddress}

            {isRight && (
              <Button onClick={onPrintCard} startIcon={<Print />} variant="contained">
                Сформировать отчет
              </Button>
            )}
          </Stack>
        }
        propKey="id"
        defaultOrder="id"
        perPage={200}
        rowsPerPageOptions={[]}
        headCells={headCells}
        hasDetailPagination={true}
        dataSource={dataSource as any[]}
        callbackSavePage={callbackSavePage}
        tablePage={pageMemo}
      />
    </LayoutPage>
  )
}

export default ApartmentsPage
