import { createApi } from '@reduxjs/toolkit/query/react'

import { generateUrl } from '#utils'

import { prepareHeadersFormDataToken, withBaseQuery } from '../helpers/prepare-headers'
import { IGenericResponse } from './types'

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: withBaseQuery(generateUrl('api/house/'), prepareHeadersFormDataToken),
  endpoints: (builder) => ({
    downloadDocument: builder.mutation<IGenericResponse, FormData>({
      query(data) {
        return {
          url: 'uploading/document',
          method: 'POST',
          body: data
        }
      }
    }),
    sendDocument: builder.mutation<IGenericResponse, { house_id: number; apartment_id: number }>({
      query(data) {
        return {
          url: 'send/document',
          method: 'POST',
          body: data
        }
      }
    })
  })
})

export const { useDownloadDocumentMutation, useSendDocumentMutation } = documentApi
