import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'

type Props = {
  telegramId: string | null
}

const TreeStep = ({ telegramId }: Props) => {
  const { user } = useRightCurrentUser()
  const users = useAppSelector((state) => state.userState.users)

  const userByTelegram = users.find((item) => item.id === user?.id)
  const userTelegramId = userByTelegram?.profile?.telegram_id

  return (
    <>
      {telegramId || userTelegramId
        ? `Синхронизация прошла успешно, ваш id - ${telegramId ?? userTelegramId}`
        : 'Вы не прошли предыдущие шаги или произошла ошибка'}
    </>
  )
}
export { TreeStep }
