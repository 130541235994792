import { RemarksByApartment } from '#api/types'

type Props = {
  remarks: RemarksByApartment[]
}

type RemarksByApartmentId = {
  [apartmentId: number]: RemarksByApartment[]
}

const useData = ({ remarks }: Props) => {
  const remarksKeyApartmentId = remarks.reduce((acc, item) => {
    const { apartment_id: apartmentId } = item

    if (apartmentId in acc) {
      return {
        ...acc,
        [apartmentId]: [...acc[apartmentId], item]
      }
    }

    return {
      ...acc,
      [apartmentId]: [item]
    }
  }, {} as RemarksByApartmentId)

  return Object.entries(remarksKeyApartmentId).map(([apartmentId, remarks]) => {
    const [{ id, description, apartment, user, remark, created_at, accepted }] = remarks

    return {
      id,
      apartment,
      description,
      apartmentId: Number(apartmentId),
      remarkName: remark?.name ?? '',
      createdAt: created_at,
      user,
      accepted,
      remarks
    }
  })
}

export { useData }
