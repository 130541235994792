const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string
const BASE_URL_PROD = process.env.REACT_APP_SERVER_ENDPOINT_PROD as string

const generateUrl = (url: string) => {
  if (process.env.NODE_ENV === 'development') {
    return `${BASE_URL}/${url}`
  }

  return `${BASE_URL_PROD}/${url}`
}

export { generateUrl }
