import { Grid, Paper } from '@mui/material'

import { useRightCurrentUser } from '#hooks/use-right-current-user'

import { CpiUser } from '../cpi-user'
import { ProfileInfo } from '../profile-info'

const PersonalInfo = () => {
  const { isExpertRight } = useRightCurrentUser()

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper sx={{ p: 2 }} elevation={3}>
          <ProfileInfo />
        </Paper>
      </Grid>
      <Grid item xs={6}></Grid>
      {isExpertRight && (
        <Grid item xs={6}>
          <CpiUser />
        </Grid>
      )}
    </Grid>
  )
}

export { PersonalInfo }
