import { Apartment } from '#api/types'

import { ExtentApartment } from '../../../../../config/constants'

type Props = {
  apartment: Apartment
  isRemarkDangerNoSpecialist: boolean
  isRemarkDanger: boolean
}

const getColor = ({ apartment, isRemarkDangerNoSpecialist, isRemarkDanger }: Props) => {
  const isRemark = isRemarkDangerNoSpecialist || isRemarkDanger

  if (apartment.document) {
    return [ExtentApartment.Accepted, ExtentApartment.InWork]
  }

  if (!apartment.document && isRemark) {
    return [ExtentApartment.NotAccepted]
  }

  if (!apartment.document && !isRemarkDangerNoSpecialist && !isRemarkDanger) {
    return [ExtentApartment.Default]
  }

  return [ExtentApartment.Default]
}

export { getColor }
