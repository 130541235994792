import { number, object, string, TypeOf } from 'zod'

const CreateApartmentAcceptanceEditSchema = object({
  id: number().optional(),
  description: string().optional(),
  period: string().min(1).max(150).optional(),
  user_id: number().optional()
})

type CreateApartmentAcceptanceEditInput = TypeOf<typeof CreateApartmentAcceptanceEditSchema>

type RemarkPhoto = {
  id: string
  original: string
  thumbnail: string
  houseId: number
}

export { type RemarkPhoto, type CreateApartmentAcceptanceEditInput, CreateApartmentAcceptanceEditSchema }
