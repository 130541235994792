import { CircularProgress } from '@mui/material'

import { Table } from '#components'

import { ToolbarUser } from '../'
import { useData, type UserByTable } from './hooks'

type Props = {
  selected: number[]
  setSelected: (selected: number[]) => void
  selectedByUser: (findId: number) => void
  destroyUsers: (selected: number[]) => void
}

const TableUser = ({ selected, setSelected, selectedByUser, destroyUsers }: Props) => {
  const { dataSource, headCells } = useData()

  if (!dataSource.length) {
    return <CircularProgress />
  }

  return (
    <Table
      tableName={`Пользователи (${dataSource.length})`}
      headCells={headCells}
      defaultOrder="fullName"
      propKey="id"
      perPage={10}
      hasDetailPagination={true}
      tablePage={1}
      dataSource={dataSource as UserByTable[]}
      selected={selected}
      setSelected={setSelected}
      toolbarElement={<ToolbarUser selectedByUser={selectedByUser} selected={selected} destroyUsers={destroyUsers} />}
    />
  )
}

export { TableUser }
