import { Clear } from '@mui/icons-material'
import { IconButton, MenuItem, Stack } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'

import { SelectedContext } from '../../context'

const HouseRemarkType = () => {
  const types = useAppSelector((state) => state.houseState.types) ?? []
  const { typeId, setTypeId, setRemarkId } = useContextSafe(SelectedContext)

  const handleChange = (event: SelectChangeEvent) => {
    setRemarkId(null)
    setTypeId(event.target.value as unknown as number)
  }

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <span>Выберите вид работ:</span>
      <Select
        value={typeId as unknown as string}
        size="small"
        onChange={handleChange}
        displayEmpty
        sx={{ width: '100%%', minWidth: 250 }}
      >
        {types.map((type) => (
          <MenuItem key={type.id} value={type.id}>
            {type.slug}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={() => setTypeId(null)}>
        <Clear />
      </IconButton>
    </Stack>
  )
}

export { HouseRemarkType }
