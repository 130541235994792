import { Button, ButtonGroup } from '@mui/material'

import { useAppSelector } from '#store'
import { getFullAddress } from '#utils'

type Props = {
  houseIds: number[]
  setHouseIds: (houseIds: number[]) => void
}

const HouseSelected = ({ houseIds, setHouseIds }: Props) => {
  const houses = useAppSelector((state) => state.houseState.houses)

  const changeHouseIds = (houseId: number) => {
    if (!houseIds.includes(houseId)) {
      setHouseIds([...houseIds, houseId])

      return
    }

    setHouseIds(houseIds.filter((id) => houseId !== id))
  }

  return (
    <>
      Выберите дом:
      <ButtonGroup sx={{ ml: 2 }} variant="outlined">
        {houses.map((house) => (
          <Button
            variant={houseIds.includes(house.id) ? 'contained' : 'outlined'}
            onClick={() => changeHouseIds(house.id)}
            key={house.id}
          >
            {getFullAddress(house)}
          </Button>
        ))}
      </ButtonGroup>
    </>
  )
}

export { HouseSelected }
