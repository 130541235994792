import { zodResolver } from '@hookform/resolvers/zod'
import { Download, Print } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { SyntheticEvent, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { number, object, TypeOf } from 'zod'

import { useDownloadDocumentMutation } from '#api/document-api'
import { useHouseMutation } from '#api/house-api'
import { useExtentMutation } from '#api/note-api'
import { Apartment } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { generateUrl, isAdmin, isSpecialist } from '#utils'

import { ColorRow, ColorRowByExtent, ExtentApartment } from '../../../../config/constants'
import { RemarkContext } from '../../contexts'
import { GridBase } from './style'
import { getColor } from './utils'

const options = {
  border: '1px solid black',
  width: '100%',
  height: '20px'
}

type Props = {
  apartment: Apartment
}

const downloadDocumentSchema = object({
  house_id: number(),
  apartment_id: number()
})

export type DownloadDocumentInput = TypeOf<typeof downloadDocumentSchema>

const RemarkPrintBtn = ({ apartment }: Props) => {
  const user = useAppSelector((state) => state.userState.user)
  const { paramsContext, remarksByApartment } = useContextSafe(RemarkContext)
  const [updateExtent] = useExtentMutation()
  const [getHouse] = useHouseMutation()
  const [downloadDocument, { isLoading }] = useDownloadDocumentMutation()
  const [name, setName] = useState<string>('')
  const [file, setFile] = useState<Blob | null>(null)
  const [extent, setExtent] = useState<ExtentApartment>(apartment.extent)
  const isRightExtentAndDoc = isAdmin(user) || isSpecialist(user)

  const methods = useForm<DownloadDocumentInput>({
    resolver: zodResolver(downloadDocumentSchema),
    defaultValues: useMemo(
      () => ({
        house_id: apartment.house_id,
        apartment_id: apartment.id
      }),
      [apartment]
    )
  })

  const { handleSubmit } = methods

  const onPrintCardApartment = () => {
    window.open(generateUrl(`pdf/remark/${paramsContext.houseId}/${paramsContext.apartmentId}`), '_blank')
  }

  const onPrintCardRemarksApartment = () => {
    window.open(generateUrl(`pdf/appeal/${paramsContext.houseId}/${paramsContext.apartmentId}`), '_blank')
  }

  const isRemarkDanger = useMemo(() => {
    if (!remarksByApartment || remarksByApartment?.length === 0) return false

    const filteredRemarks = remarksByApartment.filter((remark) => !remark.accepted)

    return filteredRemarks.length > 0
  }, [remarksByApartment])

  const isRemarkDangerNoSpecialist = useMemo(() => {
    if (!remarksByApartment || remarksByApartment?.length === 0) return false

    const filteredRemarks = remarksByApartment.filter((remark) => !remark.user_verify_id)

    return filteredRemarks.length > 0
  }, [remarksByApartment])

  const handleChangeExtent = async (event: SelectChangeEvent<string>) => {
    const data = await updateExtent({
      extent: event.target.value,
      id: apartment.id
    })

    if (data) {
      setExtent(event.target.value as unknown as ExtentApartment)
      getHouse(apartment.house_id)
    }
  }

  const onDownloadFile = async ({ nativeEvent }: SyntheticEvent) => {
    const dom: EventTarget | any = nativeEvent?.target

    if (dom === null) {
      return
    }

    const [file] = dom.files
    setName(file.name)
    setFile(file)
  }

  const onSubmitHandler: SubmitHandler<DownloadDocumentInput> = async (values: any) => {
    const data = new FormData()

    if (!file) {
      return
    }

    for (const key in values) {
      data.append(key, values[key])
    }

    data.append('document', file)

    const responce = await downloadDocument(data)

    if ('data' in responce) {
      toast.success(responce.data.message)
      getHouse(apartment.house_id)
    }
  }

  const menuItems = getColor({ apartment, isRemarkDanger, isRemarkDangerNoSpecialist })
  const isRightColor = menuItems.includes(apartment.extent)

  return (
    <GridBase>
      <Button sx={{ mr: 2 }} onClick={onPrintCardApartment} startIcon={<Print />} variant="contained">
        Карточка Замечаний
      </Button>
      <Button onClick={onPrintCardRemarksApartment} startIcon={<Print />} variant="contained">
        Карточка Обращения
      </Button>

      {isRightExtentAndDoc && (
        <FormControl sx={{ ml: 2, minWidth: 150 }} size="small">
          <InputLabel id="select-extent">Готовность квартиры</InputLabel>
          <Select
            labelId="select-extent"
            id="select-extent"
            value={extent as unknown as string}
            label="Готовность квартиры"
            onChange={handleChangeExtent}
          >
            {menuItems.map((value, key) => (
              <MenuItem value={value} key={key}>
                <div
                  style={{
                    ...options,
                    backgroundColor: ColorRowByExtent[value]
                  }}
                ></div>
              </MenuItem>
            ))}
          </Select>
          {!isRightColor && (
            <small style={{ color: ColorRow.RED, fontSize: '12px' }}>Цвет квартиры подобран неверно</small>
          )}
        </FormControl>
      )}

      {isRightExtentAndDoc && (
        <>
          <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
              <GridBase sx={{ justifyContent: 'flex-end', display: 'flex', mt: 2, mb: 2 }}>
                <Button color="primary" sx={{ ml: 2 }} variant="contained" component="label">
                  <small>{name}</small>
                  <input
                    accept="application/pdf,image/png,image/jpeg"
                    name="doc"
                    onChange={(e) => onDownloadFile(e as SyntheticEvent)}
                    multiple
                    type="file"
                    hidden
                  />
                  <Download />
                </Button>

                <LoadingButton loading={isLoading} variant="contained" sx={{ ml: 2 }} disableElevation type="submit">
                  Загрузить документ
                </LoadingButton>
              </GridBase>
            </Box>
          </FormProvider>
        </>
      )}
    </GridBase>
  )
}

export { RemarkPrintBtn }
