import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/ru'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { store } from '#store'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>
)
