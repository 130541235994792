import { HeadCell } from '../../../../../../../components/table/type'
import { RemarkStatus } from '../../../../../types'

const useCell = () => {
  const cells: HeadCell<RemarkStatus>[] = [
    {
      id: 'fio',
      label: 'Сотрудник',
      style: {
        width: '25%'
      },
      align: 'left',
      numeric: false,
      disablePadding: false,
      isSort: false
    },
    {
      id: 'all',
      label: 'Всего задач за период',
      style: {
        width: '15%',
        textAlign: 'center'
      },
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSort: false
    },
    {
      id: 'minimal',
      label: '1 - 7',
      style: {
        width: '10%',
        textAlign: 'center'
      },
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSort: false
    },
    {
      id: 'medium',
      label: '8 - 14',
      style: {
        width: '10%',
        textAlign: 'center'
      },
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSort: false
    },
    {
      id: 'maximal',
      label: '15 - 21',
      style: {
        width: '10%',
        textAlign: 'center'
      },
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSort: false
    },
    {
      id: 'critical',
      label: 'Более 21',
      style: {
        width: '10%',
        textAlign: 'center'
      },
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSort: false
    },
    {
      id: 'coefficient',
      label: 'Коэф',
      style: {
        width: '10%',
        textAlign: 'center'
      },
      align: 'center',
      numeric: false,
      disablePadding: false,
      isSort: false
    }
  ]

  return cells
}

export { useCell }
