import { useEffect, useState } from 'react'

import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'

const useTelegram = () => {
  const [telegramId, setTelegramId] = useState<null | string>(null)

  const { user } = useRightCurrentUser()
  const users = useAppSelector((state) => state.userState.users)

  const userByTelegram = users.find((item) => item.id === user?.id)
  const userTelegramId = userByTelegram?.profile?.telegram_id

  useEffect(() => {
    if (!userTelegramId) {
      return
    }

    setTelegramId(userTelegramId)
  }, [userTelegramId])

  return {
    telegramId,
    setTelegramId
  }
}

export { useTelegram }
