import { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { toast } from 'react-toastify'

import { generateUrl } from '#utils'

const authPath = '/login'

const prepareHeadersToken = async (headers: Headers) => {
  const token = localStorage.getItem('token')

  if (token) {
    headers.set('authorization', `Bearer ${token}`)
  }

  return headers
}

type PrepareHeaders =
  | ((
      headers: Headers,
      api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>
    ) => MaybePromise<Headers>)
  | undefined

const withBaseQuery = (baseUrl: string, prepareHeaders: PrepareHeaders = prepareHeadersToken) => {
  const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders })

  const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
  ) => {
    let result = await baseQuery(args, api, extraOptions)
    const status = result?.error?.status
    //status === 'PARSING_ERROR' ||
    if (status === 401) {
      const refreshResult = await baseQuery({ url: generateUrl(`api/auth/refresh`), method: 'POST' }, api, extraOptions)

      if (refreshResult?.data) {
        // @ts-ignore
        localStorage.setItem('token', refreshResult.data?.access_token)
        result = await baseQuery(args, api, extraOptions)
      } else {
        window.location.pathname = authPath
      }
    }

    if (status === 400) {
      toast.error((result as any)?.error?.data?.message ?? 'Произошла ошибка')
    }

    return result
  }

  return baseQueryWithReauth
}

const prepareHeadersFormDataToken = (headers: Headers) => {
  const token = localStorage.getItem('token')

  if (token) {
    headers.set('authorization', `Bearer ${token}`)
  }

  headers.set('Accept', '*/*')
  headers.set('Access-Control-Allow-Headers', 'origin,X-Requested-With,content-type,accept')
  headers.set('Access-Control-Allow-Credentials', 'true')
  headers.set('Access-Control-Allow-Origin', '*')
  // headers.set('Content-Type', 'application/json')
  headers.set('Content-Encoding', 'gzip')

  return headers
}

export { prepareHeadersFormDataToken, prepareHeadersToken, withBaseQuery }
