import { zodResolver } from '@hookform/resolvers/zod'
import { Dangerous, Done, Message } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Paper, Stack } from '@mui/material'
import { useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useAcceptRemarkMutation, useCommentsByAcceptanceIdMutation, useCreateCommentMutation } from '#api/note-api'
import { IUser, RemarksByApartment } from '#api/types'
import { FormInput } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

import { RemarkContext, RemarksAllViewContext } from '../../../../contexts'
import { checkingExecutionRight } from '../../../../utils'
import { CheckboxMode } from '../../../../utils/config'
import { CreateCommentByRemark, type CreateCommentByRemarkInput } from './types'

type Props = {
  remark: RemarksByApartment
  scrollToBottom: () => void
}

const FormSendComment = ({ remark, scrollToBottom }: Props) => {
  const [isAccepted, setIsAccepted] = useState(remark.accepted)
  const [createComment, { isLoading }] = useCreateCommentMutation()
  const [getCommentsByAcceptanceId] = useCommentsByAcceptanceIdMutation()
  const [acceptRemark] = useAcceptRemarkMutation()
  const { fetchActualRemarks } = useContextSafe(RemarksAllViewContext)
  const { paramsContext } = useContextSafe(RemarkContext)
  const { isSpecialistRight, user } = useRightCurrentUser()

  const methods = useForm<CreateCommentByRemarkInput>({
    resolver: zodResolver(CreateCommentByRemark),
    defaultValues: useMemo(
      () => ({
        acceptance_id: remark.id
      }),
      [remark]
    )
  })
  const { handleSubmit, reset } = methods

  const onSubmitHandler: SubmitHandler<CreateCommentByRemarkInput> = async (values: CreateCommentByRemarkInput) => {
    const data = await createComment(values)

    if ('data' in data) {
      await getCommentsByAcceptanceId(remark.id)
      fetchActualRemarks(remark, paramsContext)
      setIsAccepted(false)
      scrollToBottom()
      reset()
    }
  }

  const updateAcceptRemark = async () => {
    const { isApproval: isPermissionRight, message } = checkingExecutionRight(
      remark,
      user as unknown as IUser,
      CheckboxMode.VERIFY
    )

    if (!isPermissionRight) {
      return toast.error(message, {
        position: 'top-right'
      })
    }

    const res = await acceptRemark({
      user_verify_id: user?.id ?? remark.user_specialist_id,
      is_accept: true,
      id: remark.id
    })

    if ('data' in res) {
      fetchActualRemarks(remark, paramsContext)
      toast.success('Статус замечания обновлен', {
        position: 'top-right'
      })
    } else {
      toast.error('Ошибка при обновлении статуса', {
        position: 'top-right'
      })
    }
  }

  const isApprove = user?.id === remark.user_specialist_id || isSpecialistRight

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FormInput
                disabled={isLoading}
                multiline={true}
                rows={5}
                name="comment"
                type="text"
                label="Комментарий к отказу"
              />
            </Grid>
            <Grid justifyContent="flex-end" item xs={4}>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  order: 1,
                  pt: 3
                }}
              >
                <LoadingButton
                  color={isApprove && isAccepted ? 'error' : 'primary'}
                  loading={isLoading}
                  endIcon={isApprove && isAccepted ? <Dangerous /> : <Message />}
                  variant="contained"
                  fullWidth
                  disableElevation
                  type="submit"
                >
                  {isApprove && isAccepted ? 'Не принято' : 'Отправить'}
                </LoadingButton>

                {isApprove && !remark.user_verify_id && (
                  <LoadingButton
                    type="button"
                    disableElevation
                    disabled={isLoading}
                    variant="outlined"
                    fullWidth
                    color="success"
                    onClick={updateAcceptRemark}
                    endIcon={<Done />}
                  >
                    Принято
                  </LoadingButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Paper>
  )
}

export { FormSendComment }
