import { Stack } from '@mui/material'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import dayjs from 'dayjs'
import { useEffect } from 'react'

type Props = {
  setTime: (time: Date[]) => void
}

const SelectTimeRange = ({ setTime }: Props) => {
  const onChange =
    (name: string) =>
    (...time: unknown[]) => {
      console.log(name, time)
      setTime(time?.[0] as Date[])
    }

  useEffect(() => {
    setTime([dayjs().subtract(30, 'day') as unknown as Date, dayjs() as unknown as Date])
  }, [])

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <span>Выберите диапазон дат:</span>
      <DateRangePicker
        defaultValue={[dayjs().subtract(30, 'day'), dayjs()]}
        localeText={{ start: 'С', end: 'По' }}
        onChange={onChange('SelectTimeRange')}
      />
    </Stack>
  )
}

export { SelectTimeRange }
