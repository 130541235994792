import { Analytics, House, ManageSearch, NoteAlt, SupervisedUserCircle } from '@mui/icons-material'

import { ItemMenu } from '../types'

const RouteHome: ItemMenu = {
  name: (
    <>
      <House sx={{ mr: 0.5 }} /> Дома
    </>
  ),
  link: '/house'
}

const RouteSearchHouse: ItemMenu = {
  name: (
    <>
      <ManageSearch sx={{ mr: 0.5 }} /> Выборка
    </>
  ),
  link: '/search/info'
}

const RouteUser: ItemMenu = {
  name: (
    <>
      <SupervisedUserCircle sx={{ mr: 0.5 }} /> Пользователи
    </>
  ),
  link: '/users'
}

const RouteNotes: ItemMenu = {
  name: (
    <>
      <NoteAlt sx={{ mr: 0.5 }} /> Замечания
    </>
  ),
  link: '/notes'
}

const RouteAnalytics: ItemMenu = {
  name: (
    <>
      <Analytics sx={{ mr: 0.5 }} /> Аналитика
    </>
  ),
  link: '/home'
}

export { RouteHome, RouteUser, RouteNotes, RouteSearchHouse, RouteAnalytics }
