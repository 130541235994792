import { Email } from '@mui/icons-material'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'

import { useSendDocumentMutation } from '#api/document-api'
import { Apartment } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

type Props = {
  apartment: Apartment
}

const SendDocument = ({ apartment }: Props) => {
  const [sendDocument, { isLoading }] = useSendDocumentMutation()
  const { isAdminRight, isSpecialistRight } = useRightCurrentUser()

  const isRight = isSpecialistRight || isAdminRight

  if (!isRight || !apartment.document) {
    return null
  }

  const onSendEmail = async () => {
    const responce = await sendDocument({
      apartment_id: apartment.id,
      house_id: apartment.house_id
    })

    if ((responce as any).data?.message) {
      toast.success((responce as any).data?.message)
    } else {
      toast.error('При отправке произошла ошибка, обратитесь к администратору')
    }
  }

  return (
    <Button
      onClick={() => onSendEmail()}
      type="submit"
      variant="outlined"
      disabled={isLoading}
      size="small"
      aria-label="delete"
      sx={{ ml: 1 }}
    >
      <Email />
    </Button>
  )
}

export { SendDocument }
