import { RemarksByApartment } from '#api/types'
import { getDayDiff, getEndDate } from '#utils'

type Props = {
  remark: RemarksByApartment
}

const getDiffPeriod = ({ remark }: Props) => {
  const currentDate = new Date()
  const period = remark.period ?? remark.remark?.period ?? 0
  const endDate = getEndDate(remark.created_at, period)
  const diffDay = getDayDiff(currentDate, endDate)

  return Math.ceil(diffDay)
}

export { getDiffPeriod }
