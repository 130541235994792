import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AcceptanceComment, NotesByType, RemarksByApartment } from '#api/types'

interface INoteState {
  notesByType: NotesByType[] | null
  type: NotesByType | null
  remarks: RemarksByApartment[]
  comments: AcceptanceComment[]
}

const initialState: INoteState = {
  notesByType: [],
  remarks: [],
  type: null,
  comments: []
}

export const noteSlice = createSlice({
  initialState,
  name: 'noteSlice',
  reducers: {
    setNotes: (state, action: PayloadAction<NotesByType[]>) => {
      state.notesByType = action.payload
    },
    setNote: (state, action: PayloadAction<NotesByType>) => {
      state.type = action.payload
    },
    setAllRemarks: (state, action: PayloadAction<RemarksByApartment[]>) => {
      state.remarks = action.payload
    },
    setCommentsByAcceptanceId: (state, action: PayloadAction<AcceptanceComment[]>) => {
      state.comments = action.payload
    }
  }
})

export default noteSlice.reducer

export const { setNotes, setNote, setAllRemarks, setCommentsByAcceptanceId } = noteSlice.actions
