import { zodResolver } from '@hookform/resolvers/zod'
import { Close, Delete } from '@mui/icons-material'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Button,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  Tab,
  Typography
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import ReactImageGallery from 'react-image-gallery'
import { toast } from 'react-toastify'

import { useUpdateApartmentAcceptanceMutation } from '#api/download-api'
import { ApartmentPhoto, Roles } from '#api/types'
import { FormInput, FormSelect } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'
import { type RemarksByApartment } from '#types/remark'
import { keyBy } from '#utils'

import { buildOptions } from '../../../notes/utils'
import { RemarkContext, RemarksAllViewContext } from '../../contexts'
import { RemarkComments } from '../remark-comments'
import { useImages } from '../sidebar-remark/hooks'
import { ImagePhoto, type Item } from './components'
import { DrawerHeader } from './style'
import { type CreateApartmentAcceptanceEditInput, CreateApartmentAcceptanceEditSchema, type RemarkPhoto } from './types'
import { getDefaultImages, getImages, getRemarkPeriod, getRemarkUserId } from './utils'

type Props = {
  open: boolean
  setOpen: () => void
  remark: RemarksByApartment
  houseId: number
}

const RemarkPhotoSidebar = ({ open, setOpen, remark, houseId }: Props) => {
  const [tab, setTab] = useState('1')
  const [updateApartmentAcceptanceMutation, { isSuccess, isLoading }] = useUpdateApartmentAcceptanceMutation()
  const { fetchActualRemarks } = useContextSafe(RemarksAllViewContext)
  const { paramsContext } = useContextSafe(RemarkContext)
  const { images, setImages, destroyImage, onDownloadFiles } = useImages()
  const { isAdminRight, isSpecialistRight, user } = useRightCurrentUser()
  const isRightEdit = isAdminRight || isSpecialistRight

  const comments = useAppSelector((state) => state.noteState.comments)
  const commentsUserById = keyBy('user_id', comments)
  // @ts-ignore
  const hasCommentCurrentUser = Boolean(commentsUserById?.[user?.id] ?? null) && isRightEdit

  const [currentImages, setCurrentImages] = useState<RemarkPhoto[]>(getDefaultImages(remark, houseId))

  const methods = useForm<CreateApartmentAcceptanceEditInput>({
    resolver: zodResolver(CreateApartmentAcceptanceEditSchema),
    defaultValues: useMemo(
      () => ({
        id: remark.id,
        description: remark?.description ?? '',
        period: getRemarkPeriod(remark),
        user_id: getRemarkUserId(remark)
      }),
      [remark]
    )
  })

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const users = useAppSelector((state) => state.userState.users) ?? []

  const {
    handleSubmit
    // formState: { isSubmitSuccessful }
  } = methods

  const onSubmitHandler: SubmitHandler<CreateApartmentAcceptanceEditInput> = async (
    values: CreateApartmentAcceptanceEditInput
  ) => {
    try {
      const data = new FormData()

      for (const key in images) {
        data.append('images[]', images[key]?.file)
      }

      for (const key in values) {
        data.append(key, String((values as any)[key] ?? ''))
      }

      const dataImages = await updateApartmentAcceptanceMutation(data)

      if ((dataImages as any)?.data?.images?.length > 0) {
        const newPhotoData: ApartmentPhoto[] = (dataImages as any).data.images
        setCurrentImages(getImages(newPhotoData, houseId))
      }
      setImages([])
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Замечание успешно изменено')
      fetchActualRemarks(remark, paramsContext)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const optionsUsers = buildOptions(
    users.filter((user) => {
      const isExpert = (user?.roles ?? []).map(({ name }) => name).includes(Roles.EXPERT)

      return isExpert
    })
  )

  const DrawerList = (
    <Box
      sx={{
        pb: '2rem',
        pl: '2rem',
        pr: '2rem'
      }}
    >
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Информация" value="1" />
              <Tab label="Фото" value="2" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormProvider {...methods}>
                  <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off">
                    <FormInput
                      disabled={!isRightEdit || Boolean(remark.accepted) || hasCommentCurrentUser}
                      multiline={true}
                      rows={5}
                      mb={2}
                      name="description"
                      type="text"
                      label="Комментарий"
                    />

                    <FormSelect
                      disabled={!isRightEdit || Boolean(remark.accepted) || hasCommentCurrentUser}
                      mb={2}
                      name="user_id"
                      type="number"
                      label="Исполнитель *"
                      options={optionsUsers}
                    />

                    <FormInput
                      disabled={!isRightEdit || Boolean(remark.accepted) || hasCommentCurrentUser}
                      mb={2}
                      name="period"
                      type="number"
                      label="Период *"
                    />
                    {isRightEdit && (
                      <Button
                        disabled={Boolean(remark.accepted) || hasCommentCurrentUser}
                        variant="contained"
                        component="label"
                        sx={{ mb: 2 }}
                      >
                        Загрузить фото
                        <input
                          accept="image/*"
                          name="photos[]"
                          onChange={(e) => onDownloadFiles(e as SyntheticEvent)}
                          multiple
                          type="file"
                          hidden
                        />
                      </Button>
                    )}

                    {isRightEdit && !remark.accepted && (
                      <LoadingButton
                        disabled={hasCommentCurrentUser}
                        loading={isLoading}
                        variant="contained"
                        fullWidth
                        disableElevation
                        type="submit"
                      >
                        Сохранить
                      </LoadingButton>
                    )}
                  </Box>

                  <ImageList sx={{ width: '100%', mt: 2 }}>
                    {images.map(({ image }, idx) => (
                      <ImageListItem key={image}>
                        <img src={image} loading="lazy" />
                        <ImageListItemBar
                          actionIcon={
                            <IconButton color="error" onClick={() => destroyImage(idx)}>
                              <Delete />
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </FormProvider>
              </Grid>
              <Grid xs={6}>
                <RemarkComments remark={remark} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <ReactImageGallery
              autoPlay={false}
              showPlayButton={false}
              renderItem={(item: Item) => (
                <ImagePhoto
                  key={item.id}
                  currentImages={currentImages}
                  setCurrentImages={setCurrentImages}
                  item={item}
                  remark={remark}
                  houseId={houseId}
                />
              )}
              items={currentImages}
            />
          </TabPanel>
        </TabContext>
      </List>
    </Box>
  )

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: { width: '95%' }
        }}
        open={open}
        role="presentation"
        anchor="right"
      >
        <DrawerHeader>
          <Grid sx={{ width: '100%', paddingLeft: '2rem' }}>
            <Typography variant="h5">Подробная информация</Typography>
          </Grid>
          <IconButton onClick={() => setOpen()}>
            <Close />
          </IconButton>
        </DrawerHeader>

        {DrawerList}
      </Drawer>
    </div>
  )
}

export { RemarkPhotoSidebar }
