import { Pagination } from '@mui/lab'
import { Box, Table as MuiTable, Paper, Stack, TableBody, TableContainer } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import React, { ReactElement, ReactNode, useMemo } from 'react'

import { EnhancedTableHead, TableToolbar } from './components'
import { type HeadCell, Order } from './type'
import { getComparator, stableSort } from './utils'

type Props<T> = {
  headCells: HeadCell<T>[]
  dataSource: T[]
  defaultOrder: keyof T
  propKey: keyof T
  perPage: number
  hasTableToolbar?: boolean
  tableName?: string | ReactElement
  toolbarElement?: ReactNode
  filterElement?: ReactNode
  isSelect?: boolean
  selected?: any[]
  setSelected?: (selected: any[]) => void
  rowsPerPageOptions?: number[]
  callbackSavePage?: (page: number) => void
  isActiveSavePageApartment?: boolean
  hasDetailPagination?: boolean
  tablePage?: number
  maxHeight?: string | null
}

type HeaderProperty = {
  [key: string]: HeadCell
}

const Table = <T,>(props: Props<T>) => {
  const {
    headCells,
    defaultOrder,
    dataSource,
    propKey,
    perPage,
    hasTableToolbar = true,
    tableName,
    isSelect = true,
    selected = [],
    setSelected = () => null,
    toolbarElement = null,
    filterElement = null,
    rowsPerPageOptions = [5, 10, 25],
    callbackSavePage,
    hasDetailPagination = false,
    tablePage = 0,
    maxHeight = '80vh'
  } = props

  const headerProperty: HeaderProperty = headCells.reduce((acc, item) => {
    const { id } = item

    return {
      ...acc,
      [id]: item
    }
  }, {})

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof T>(defaultOrder)
  const [page, setPage] = React.useState(tablePage)
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage)
  const countPage = useMemo(() => Math.ceil(dataSource.length / rowsPerPage), [dataSource, rowsPerPage])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = dataSource.map((n) => n[propKey])
      setSelected(newSelected)

      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    if (hasDetailPagination) {
      setPage(newPage)
    } else {
      setPage(newPage)
    }

    callbackSavePage?.(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1
  const isMorePage = countPage > 1
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = isMorePage
    ? page > 0
      ? Math.max(0, (1 + (hasDetailPagination ? page - 1 : page)) * rowsPerPage - dataSource.length)
      : 0
    : 0

  const visibleRows = React.useMemo(
    () =>
      stableSort<any>(dataSource, getComparator(order, orderBy)).slice(
        (hasDetailPagination ? page - 1 : page) * rowsPerPage,
        (hasDetailPagination ? page - 1 : page) * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, dataSource]
  )

  const heightStyle = maxHeight ? { maxHeight } : {}

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar
          hasTableToolbar={hasTableToolbar}
          toolbarElement={toolbarElement}
          filterElement={filterElement}
          tableName={tableName}
          selected={selected}
          numSelected={selected.length}
        />

        <TableContainer sx={heightStyle}>
          <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle" stickyHeader={true} size="medium">
            <EnhancedTableHead
              isSelect={isSelect}
              headCells={headCells}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              order={order}
              orderBy={orderBy}
              rowCount={dataSource.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row[propKey])
                const labelId = `enhanced-table-checkbox-${index}`
                const keys = Object.keys(headerProperty)
                const rowStyle = row?.['options.row-style'] ?? {}

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row[propKey])}
                    role="checkbox"
                    style={rowStyle}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row[propKey]}-${index}`}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    {isSelect && (
                      <TableCell
                        sx={{
                          borderRight: '1px solid rgba(224, 224, 224, 1)'
                        }}
                        padding="checkbox"
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                    )}

                    {keys.map((keyCol, indexCol) => {
                      const renderElement = headerProperty[keyCol]?.render
                      const align = headerProperty[keyCol]?.align ?? 'right'
                      const colorCell = row?.[`${keyCol}.options.cell-style`] ?? {}
                      const value = row[keyCol]

                      if (!indexCol) {
                        return (
                          <TableCell
                            component="th"
                            id={labelId}
                            key={`th-${indexCol}-${row[keyCol]}`}
                            align={align}
                            style={colorCell}
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)'
                            }}
                          >
                            {renderElement ? renderElement(value, row) : row[keyCol]}
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell
                          style={colorCell}
                          key={`th-${indexCol}-${row[keyCol]}`}
                          align={align}
                          sx={{
                            borderRight: '1px solid rgba(224, 224, 224, 1)'
                          }}
                        >
                          {renderElement ? renderElement(value, row) : row[keyCol]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
          {isMorePage && (
            <>
              {hasDetailPagination ? (
                <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end', pt: 2, pb: 2 }}>
                  <Pagination
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                    count={countPage}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              ) : (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={dataSource.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </>
          )}
        </TableContainer>
      </Paper>
    </Box>
  )
}

export { Table }
