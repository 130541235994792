import { Clear } from '@mui/icons-material'
import { IconButton, MenuItem, Stack } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { Roles } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useAppSelector } from '#store'
import { getFullName } from '#utils'

import { SelectedContext } from '../../context'

const UserSelect = () => {
  const users = useAppSelector((state) => state.userState.users)
    .filter((user) => user?.roles?.[0]?.name === Roles.EXPERT)
    .sort((a, b) => {
      const fullNameA = getFullName(a)
      const fullNameB = getFullName(b)

      if (fullNameA < fullNameB) {
        return -1
      }
      if (fullNameA > fullNameB) {
        return 1
      }

      return 0
    })

  const { userId, setUserId } = useContextSafe(SelectedContext)

  const handleChange = (event: SelectChangeEvent) => {
    setUserId(event.target.value as unknown as number)
  }

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <span>Выберите исполнителя:</span>
      <Select
        size="small"
        value={String(userId)}
        onChange={handleChange}
        displayEmpty
        sx={{ width: '100%%', minWidth: 250 }}
      >
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {getFullName(user)}
          </MenuItem>
        ))}
      </Select>
      <IconButton onClick={() => setUserId(null)}>
        <Clear />
      </IconButton>
    </Stack>
  )
}

export { UserSelect }
