import { Container, Stack, Typography } from '@mui/material'
import { isValidElement, type ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { usePreloadingData } from '#hooks/use-preloading-data'
import { useAppSelector } from '#store'

import { setIsPreloadingData } from '../../redux/features/userSlice'
import { Breadcrumb, BreadcrumbsType } from '../Breadcrumb'

type Props = {
  title?: string | ReactElement
  children: ReactElement | string
  afterTitleElement?: ReactElement
  breadcrumbs?: BreadcrumbsType[]
  isFixed?: boolean
}

const LayoutPage = ({ children, title, afterTitleElement = <></>, breadcrumbs }: Props) => {
  const dispatch = useDispatch()
  const { fetchAllCollection } = usePreloadingData()
  const isPreloadingData = useAppSelector((state) => state.userState.isPreloadingData)

  useEffect(() => {
    if (isPreloadingData) {
      return
    }

    fetchAllCollection()
      .then((data) => {
        console.log('Данные полученны', data)
        dispatch(setIsPreloadingData(true))
      })
      .catch((err) => {
        console.error('При формировании данных возникла ошибка', err)
        dispatch(setIsPreloadingData(false))
      })
  }, [isPreloadingData])

  return (
    <Container sx={{ minWidth: '95%' }} fixed>
      <Stack sx={{ mt: 3, position: 'relative' }} spacing={2}>
        {breadcrumbs && <Breadcrumb breadcrumbs={breadcrumbs} />}

        {title && (
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {isValidElement(title) ? title : <Typography variant="h5">{title}</Typography>}

            {afterTitleElement}
          </Stack>
        )}
        {children}
      </Stack>
    </Container>
  )
}

export { LayoutPage }
