import { PrintTwoTone } from '@mui/icons-material'
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import { IconButton } from '@mui/material/'

import { LayoutPage } from '#components'
import { useContextSafe } from '#hooks/use-context-safe'
import { generateUrl } from '#utils'

import { HouseRemarkType, HouseSearchResult, HouseSelected, UserSelect } from './components'
import { SelectedContext, SelectedContextProvider } from './context'

const FilterPage = () => {
  const { houseId, remarkId, typeId, userId, active } = useContextSafe(SelectedContext)
  const isDisableButton = !houseId

  const onLinkPrintRemark = () => {
    window.open(
      generateUrl(`pdf/filter/remark/${houseId}/${typeId}/${remarkId}?active=${active}&userId=${userId}`),
      '_blank'
    )
  }

  return (
    <LayoutPage title="Выборка">
      <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
        <CardContent>
          <Stack sx={{ mb: 2 }} justifyContent="space-between" direction="row" spacing={2}>
            <Typography variant="h6">Поиск замечаний по дому</Typography>

            <IconButton disabled={isDisableButton} onClick={onLinkPrintRemark} size="large" color="primary">
              <PrintTwoTone fontSize="large" color="error" />
            </IconButton>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HouseSelected />
            </Grid>
            <Grid item xs={12}>
              <HouseRemarkType />
            </Grid>
            <Grid item xs={12}>
              <UserSelect />
            </Grid>
            <Grid item xs={12}>
              <HouseSearchResult />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </LayoutPage>
  )
}

const FilterPageWithContext = () => (
  <SelectedContextProvider>
    <FilterPage />
  </SelectedContextProvider>
)

export default FilterPageWithContext
