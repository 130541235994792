import { Typography } from '@mui/material'

import { useAppSelector } from '#store'

const ProfileInfo = () => {
  const user = useAppSelector((state) => state.userState.user)

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Личные данные
      </Typography>
      <Typography gutterBottom>
        <strong>Id:</strong> {user?.id}
      </Typography>
      <Typography gutterBottom>
        <strong>Email:</strong> {user?.email}
      </Typography>
      <Typography gutterBottom>
        <strong>Роль:</strong> {user?.role}
      </Typography>
    </>
  )
}
export { ProfileInfo }
