import { useState } from 'react'

import { RemarksByApartment } from '#api/types'
import { Table } from '#components'

import { useCell, useDataSidebarTable } from '../../hooks'
import { RemarkPhotoSidebar } from '../remark-photo-sidebar'

type Props = {
  remarks: RemarksByApartment[]
  houseId: number
}

const RemarksTable = ({ remarks, houseId }: Props) => {
  const [currentRemark, setCurrentRemark] = useState<RemarksByApartment | null>(null)

  const { headCells } = useCell({ hasDetailTable: true, setCurrentRemark })
  const { dataSource } = useDataSidebarTable(remarks)

  return (
    <>
      <Table
        isSelect={false}
        hasTableToolbar={false}
        propKey="end_time"
        defaultOrder="end_time"
        perPage={25}
        headCells={headCells}
        hasDetailPagination={true}
        tablePage={1}
        dataSource={dataSource as any[]}
      />

      {currentRemark && (
        <RemarkPhotoSidebar
          houseId={houseId}
          open={Boolean(currentRemark)}
          setOpen={() => setCurrentRemark(null)}
          remark={currentRemark}
        />
      )}
    </>
  )
}

export { RemarksTable }
