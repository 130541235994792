import { Divider, Stack } from '@mui/material'

import { AcceptanceComment, RemarksByApartment } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'
import { useAppSelector } from '#store'
import { generateUrl } from '#utils'

import { Comment } from '../comment'

type Props = {
  remark: RemarksByApartment
}

const Comments = ({ remark }: Props) => {
  const comments = useAppSelector((state) => state.noteState.comments)
  const { user } = useRightCurrentUser()

  const onPrintCard = () => {
    window.open(generateUrl(`pdf/comments/${remark.id}`), '_blank')
  }

  return (
    <Stack spacing={2}>
      <Comment
        name={remark.remark?.name}
        comment={
          {
            comment: remark.description,
            user_id: remark.user_id,
            created_at: remark.created_at,
            user: remark.user
          } as unknown as AcceptanceComment
        }
        onPrintCard={onPrintCard}
      />

      <Divider />

      {comments.map((comment) => (
        <Comment authUserId={user?.id} comment={comment} key={comment.id} />
      ))}
    </Stack>
  )
}

export { Comments }
