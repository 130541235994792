import { Card, Checkbox, Grid } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { ChangeEvent, useState } from 'react'

import { useUpdateDateApartmentMutation, useUpdateReadinessApartmentMutation } from '#api/house-api'
import { Apartment } from '#api/types'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

type Props = {
  apartment: Apartment
}

const ApartmentDate = ({ apartment }: Props) => {
  const [isReadiness, setIsReadiness] = useState(Boolean(apartment.readiness))
  const [updateDateApartmentMutation, { isLoading }] = useUpdateDateApartmentMutation()
  const [updateReadinessApartment, { isLoading: isLoadingReadiness }] = useUpdateReadinessApartmentMutation()
  const { isSpecialistRight, isAdminRight } = useRightCurrentUser()
  const hasRight = isSpecialistRight || isAdminRight

  const onChange =
    (name: string) =>
    (...args: unknown[]) => {
      console.log(name, args)
      updateDateApartmentMutation({
        date: (args[0] as Dayjs).add(1, 'day') as unknown as Date,
        name,
        id: apartment.id
      })
    }

  const onChangeReadiness = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const result = await updateReadinessApartment({
      readiness: checked,
      id: apartment.id
    })

    if ('data' in result && result.data.isSave) {
      setIsReadiness(Boolean(checked))
    }
  }

  return (
    <Card sx={{ p: 2, mb: 4 }}>
      <Grid container gap={2}>
        <Grid gap={4} container xs={12} spacing={2}>
          <Grid item xs={5} container gap={2} alignItems="center" justifyContent="space-between">
            Планируемая дата готовности квартиры:
            <DatePicker
              disabled={isLoading || !hasRight}
              defaultValue={apartment?.date_planned_readiness && dayjs(apartment.date_planned_readiness)}
              onChange={onChange('date_planned_readiness')}
            />
          </Grid>
          <Grid item xs={5} container gap={2} alignItems="center" justifyContent="space-between">
            Планируемая дата передачи квартиры:
            <DatePicker
              disabled={isLoading || !hasRight}
              defaultValue={apartment?.date_planned_readiness && dayjs(apartment.date_planned_transfer)}
              onChange={onChange('date_planned_transfer')}
            />
          </Grid>
        </Grid>
        <Grid gap={4} container xs={12} spacing={2}>
          <Grid item xs={5} container gap={4} alignItems="center">
            Готовность:
            <Checkbox
              checked={isReadiness}
              disabled={isLoadingReadiness || !hasRight}
              onChange={onChangeReadiness}
              defaultChecked
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />
          </Grid>
          <Grid item xs={5} container gap={2} alignItems="center" justifyContent="space-between">
            Дата передачи квартиры:
            <DatePicker
              disabled={isLoading || !hasRight}
              defaultValue={apartment?.date_transfer && dayjs(apartment.date_transfer)}
              onChange={onChange('date_transfer')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export { ApartmentDate }
