import { Delete } from '@mui/icons-material'
import { Box, Card, CardActions, CardMedia, IconButton } from '@mui/material'
import { toast } from 'react-toastify'

import { useRemovePhotoMutation } from '#api/note-api'
import { RemarksByApartment } from '#api/types'
import { useContextSafe } from '#hooks/use-context-safe'
import { useRightCurrentUser } from '#hooks/use-right-current-user'

import { RemarkContext, RemarksAllViewContext } from '../../../../contexts'
import type { RemarkPhoto } from '../../types'

type Item = { original: string; id: string; houseId: number }

type Props = {
  item: Item
  houseId: number
  remark: RemarksByApartment
  currentImages: RemarkPhoto[]
  setCurrentImages: (photos: RemarkPhoto[]) => void
}

const ImagePhoto = ({ item, setCurrentImages, houseId, currentImages, remark }: Props) => {
  const { fetchActualRemarks } = useContextSafe(RemarksAllViewContext)
  const [removePhoto] = useRemovePhotoMutation()
  const { paramsContext } = useContextSafe(RemarkContext)
  const { isAdminRight, isSpecialistRight } = useRightCurrentUser()
  const isRightEdit = isAdminRight || isSpecialistRight

  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 300
  console.log('!@vh', vh)
  const onRemoveItem = async () => {
    const request = await removePhoto({
      id: Number(item.id),
      houseId
    })

    if ('data' in request) {
      setCurrentImages(currentImages.filter(({ id }) => id !== item.id))

      return fetchActualRemarks(remark, paramsContext)
    }

    toast.error('Ошибка при уделние фото', {
      position: 'top-right'
    })
  }

  return (
    <Box sx={{ p: 2 }}>
      <Card>
        <CardMedia height={vh} component="img" image={item.original} />
        {!remark.accepted && (
          <CardActions>
            {isRightEdit && (
              <IconButton color="error" onClick={onRemoveItem}>
                <Delete />
              </IconButton>
            )}
          </CardActions>
        )}
      </Card>
    </Box>
  )
}

export { ImagePhoto, type Item }
