import { getNameByType } from '#utils'

import { HeadCell } from '../../../../../../../components/table/type'
import { RemarkCell } from '../components'
import { RemarkByHome } from '../types'

const useCell = () => {
  const headCells: HeadCell<RemarkByHome>[] = [
    {
      id: 'apartmentId',
      numeric: true,
      disablePadding: false,
      label: 'Помещение',
      render: (_, { apartment }) => {
        if (!apartment) {
          return ''
        }
        const nameByType = getNameByType(apartment.type, true)

        return `${nameByType} №${apartment.number}`
      }
    },
    {
      id: 'remarks',
      numeric: false,
      isSort: false,
      disablePadding: false,
      label: 'Информация',
      render: (_, { remarks }) => <RemarkCell remarks={remarks} />
    }
  ]

  return headCells
}

export { useCell }
