import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type PageByHome = {
  [key: string]: number
}

interface IApartmentsPageState {
  pageByHome: PageByHome
}

const initialState: IApartmentsPageState = {
  pageByHome: {}
}

const apartmentsPageSlice = createSlice({
  initialState,
  name: 'apartmentsPageSlice',
  reducers: {
    setPageApartment: (state, action: PayloadAction<PageByHome>) => {
      state.pageByHome = action.payload
    }
  }
})

export default apartmentsPageSlice.reducer

export const { setPageApartment } = apartmentsPageSlice.actions
