import { any, number, object, string, TypeOf } from 'zod'

const createHouseSchema = object({
  drop: string().optional(),
  number: string().min(1).max(3),
  street: string().min(3).max(150),
  director: string().min(3).max(150),
  developer: string().min(3).max(150),
  count_apartments: string().min(1).max(2),
  count_office: string().min(0).max(2).default('0'),
  count_storage: string().min(0).max(2).default('0'),
  count_parking: string().min(1).max(2).default('0')
})

type CreateHouseInput = TypeOf<typeof createHouseSchema>

const updateHouseSchema = object({
  id: number(),
  drop: string().nullable().optional(),
  number: any().optional(),
  street: string().min(3).max(150),
  director: string().min(3).max(150),
  developer: string().min(3).max(150)
})

type UpdateHouseInput = TypeOf<typeof updateHouseSchema>

export { createHouseSchema, updateHouseSchema, type CreateHouseInput, type UpdateHouseInput }
